.terms h2,
.terms h3 {
  @apply mt-6 font-bold;
}

.terms h1 {
  @apply mb-8 text-center text-2xl font-bold;
}

.terms h2 {
  @apply text-xl;
}

.terms h3 {
  @apply text-lg;
}

.terms p,
.terms ul,
.terms li {
  @apply mt-2;
}

.terms p,
.terms ul,
.terms li {
  @apply text-sm 2xl:text-base;
}

.terms {
  @apply flex flex-col p-2 font-worksans text-gray-800;
}
